<template>
  <div class="profile">
    <transition name="fade" :duration="3000">
      <div id="overlay" v-if="notification">
        <img
          v-if="$route.query.type === 'success'"
          class="img-notification"
          src="../assets/img/success.svg"
        />
        <img
          v-if="$route.query.type === 'canceled'"
          class="img-notification"
          src="../assets/img/failed.svg"
        />
      </div>
    </transition>
    <div
      class="
        d-flex
        flex-column
        justify-content-center
        align-items-center
        avatar-name-section
      "
    >
      <img
        :src="
          $store.state.user.avatar
            ? $store.state.apiUrl + $store.state.user.avatar.path
            : require('../assets/img/avatar.png')
        "
        class="avatar"
      />
      <div class="change-avatar">
        <input
          type="file"
          id="new-avatar"
          ref="new-avatar"
          style="display: none"
          @change="changeAvatar($event)"
        />
        <a href="#" @click="pickFile">Changer l’avatar</a>
      </div>
      <h2>{{ $store.state.user.name }}</h2>
      <router-link to="/edit-profile" class="main-button"
        >Modifier le profil</router-link
      >
      <div class="">
        <p>
          Formule actuelle: <b>{{ plan }}</b>
        </p>
        <!-- <p>05.05.22/05.05.23</p> -->
      </div>
    </div>
    <div class="phrases">
      <div class="d-flex flex-column justify-content-between">
        <div
          data-aos-delay="50"
          data-aos-once="true"
          data-aos-anchor-placement="bottom-bottom"
          data-aos="fade-down"
          class="phrase-item box-right w-100"
          v-for="(item, index) in toBeShown"
          :key="index"
          @click="$router.push(`/phrase/${item.id}`)"
        >
          <p>
            <i>"{{ item.phrase.phrase }}"</i> &nbsp;
            {{ item.phrase.author.name }}
          </p>
          <div class="phrase-footer">
            <div class="phrase-rating">
              <span v-if="item.rating">{{
                getStars(item.rating).join("")
              }}</span>
            </div>
            <div class="phrase-date">{{ formatDate(item.date) }}</div>
          </div>
        </div>
      </div>
      <div v-if="phrases.length" class="d-flex justify-content-between gap-3">
        <button
          class="main-button"
          @click="prevPage"
          :disabled="currentPage == 1"
        >
          ▲ Voir moins
        </button>
        <button
          class="main-button"
          @click="nextPage"
          :disabled="currentPage == totalPages"
        >
          ▼ Voir plus
        </button>
      </div>
      <div v-else class="text-center mt-5">Plus d’Edays</div>
    </div>
  </div>
</template>

<script>
import { pushApi } from "@/pushApi";
import { getBrowserName } from "@/helpers/browser-name";
import * as moment from "moment";

export default {
  data() {
    return {
      phrases: [],
      currentPage: 1,
      notification: false,
      plan: "free",
    };
  },
  computed: {
    toBeShown() {
      if (this.phrases.length) {
        return this.phrases.slice(0, this.currentPage * 4);
      }
      return [];
    },
    totalPages() {
      return Math.ceil(this.phrases.length / 4);
    },
  },
  async created() {
    const res = await this.$http.account.getProfile();
    const profile = res.data;
    this.plan = profile.plan;
    if (
      this.$route.query.type === "success" ||
      this.$route.query.type === "canceled"
    ) {
      this.notification = true;
      setTimeout(() => {
        this.notification = false;
      }, 3000);
    }

    let isSubscribed = false;
    const askPermission = async () => {
      if (isSubscribed) return;
      isSubscribed = true;

      try {
        await pushApi.registerServiceWorker();
      } catch (err) {
        console.log(err);
      }

      try {
        await this.$http.auth.subscribeDevice(
          Object.assign(pushApi.pushSubscription, {
            clientName: getBrowserName(),
          })
        );

        this.$store.commit("SET_DEVICE_REGISTRATION", true);
      } catch (err) {
        console.log(err);
      }
    };

    document.addEventListener("click", askPermission);
    document.addEventListener("mousemove", askPermission);
  },
  async mounted() {
    const res = await this.$http.account.getPhrases();
    const phrases = res.data.data;

    this.phrases = phrases;
  },
  methods: {
    getStars(r) {
      const arr = [];

      for (let i = 1; i <= 5; i++) {
        arr.push(r < i ? "☆" : "★");
      }

      return arr;
    },
    formatDate(d) {
      return moment(d).format("L");
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    prevPage() {
      this.currentPage = this.currentPage - 1 || 1;
    },
    pickFile(e) {
      e.preventDefault();
      this.$refs["new-avatar"].click();
    },
    async changeAvatar(e) {
      const media = await this.$http.media.upload(e.target.files[0]);

      await this.$http.account.changeAvatar(media.id);

      const user = (await this.$http.account.getProfile()).data;

      this.$store.commit("UPDATE_USER", user);

      location.reload();
    },
  },
};
</script>
